$white: #fff;
$black: #282828;
$yellow: #f9f079;
$red: #e83f41;
$pink: #f9cdce;
$green: #008278;
$teal: #d7ebe6;


$space: 40px;

$mobile: "only screen and (min-width: 576px)";
$tablet: "only screen and (min-width: 768px)";
$laptop: "only screen and (min-width: 992px)";
$desktop: "only screen and (min-width: 1200px)";
$desktop-large: "only screen and (min-width: 1600px)";