.grid {
	height: 100%;
	width: 100%;
	padding: 0 $space;

	@media #{$laptop} {
		padding: 0 $space * 4;
	}
}

.grid--container {
	position: relative;
	margin: 0 auto;
}

.section {
	padding: $space 0;
}

.row {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: calc(100% + #{$space * 2});
	margin-left: -$space;
	margin-right: -$space;
}

[class^="col"] {
	width: 100%;
	padding-left: $space;
	padding-right: $space;
}

.col--auto {
	width: auto;
}

.col--xs-1 {
	width: 8.3333%;
}

.col--xs-2 {
	width: 16.6667%;
}

.col--xs-3 {
	width: 25%;
}

.col--xs-4 {
	width: 33.3333%;
}

.col--xs-5 {
	width: 41.6667%;
}

.col--xs-6 {
	width: 50%;
}

.col--xs-7 {
	width: 58.3334%;
}

.col--xs-8 {
	width: 66.6667%;
}

.col--xs-9 {
	width: 75%;
}

.col--xs-10 {
	width: 83.3334%;
}

.col--xs-11 {
	width: 91.6667%;
}

.col--xs-12 {
	width: 100%;
}

.hidden--xs {
	display: none !important;
}

@media #{$tablet} {
	.hidden--xs {
		display: block !important;
	}

	.col--sm-1 {
		width: 8.3333%;
	}

	.col--sm-2 {
		width: 16.6667%;
	}

	.col--sm-3 {
		width: 25%;
	}

	.col--sm-4 {
		width: 33.3333%;
	}

	.col--sm-5 {
		width: 41.6667%;
	}

	.col--sm-6 {
		width: 50%;
	}

	.col--sm-7 {
		width: 58.3334%;
	}

	.col--sm-8 {
		width: 66.6667%;
	}

	.col--sm-9 {
		width: 75%;
	}

	.col--sm-10 {
		width: 83.3334%;
	}

	.col--sm-11 {
		width: 91.6667%;
	}

	.col--sm-12 {
		width: 100%;
	}

	.col--sm-offset-1 {
		margin-left: 8.3333%;
	}

	.col--sm-offset-2 {
		margin-left: 16.6667%;
	}

	.col--sm-offset-3 {
		margin-left: 25%;
	}

	.col--sm-offset-4 {
		margin-left: 33.3333%;
	}

	.col--sm-offset-5 {
		margin-left: 41.6667%;
	}

	.col--sm-offset-6 {
		margin-left: 50%;
	}

	.col--sm-offset-7 {
		margin-left: 58.3334%;
	}

	.col--sm-offset-8 {
		margin-left: 66.6667%;
	}

	.col--sm-offset-9 {
		margin-left: 75%;
	}

	.col--sm-offset-10 {
		margin-left: 83.3334%;
	}

	.col--sm-offset-11 {
		margin-left: 91.6667%;
	}

	.hidden--sm {
		display: none !important;
	}
}

@media #{$laptop} {
	.col--md-1 {
		width: 8.3333%;
	}

	.col--md-2 {
		width: 16.6667%;
	}

	.col--md-3 {
		width: 25%;
	}

	.col--md-4 {
		width: 33.3333%;
	}

	.col--md-5 {
		width: 41.6667%;
	}

	.col--md-6 {
		width: 50%;
	}

	.col--md-7 {
		width: 58.3334%;
	}

	.col--md-8 {
		width: 66.6667%;
	}

	.col--md-9 {
		width: 75%;
	}

	.col--md-10 {
		width: 83.3334%;
	}

	.col--md-11 {
		width: 91.6667%;
	}

	.col--md-12 {
		width: 100%;
	}
}

@media #{$desktop} {
	.col--lg-1 {
		width: 8.3333%;
	}

	.col--lg-2 {
		width: 16.6667%;
	}

	.col--lg-3 {
		width: 25%;
	}

	.col--lg-4 {
		width: 33.3333%;
	}

	.col--lg-5 {
		width: 41.6667%;
	}

	.col--lg-6 {
		width: 50%;
	}

	.col--lg-7 {
		width: 58.3334%;
	}

	.col--lg-8 {
		width: 66.6667%;
	}

	.col--lg-9 {
		width: 75%;
	}

	.col--lg-10 {
		width: 83.3334%;
	}

	.col--lg-11 {
		width: 91.6667%;
	}

	.col--lg-12 {
		width: 100%;
	}

	.col--lg-offset-1 {
		margin-left: 8.3333%;
	}

	.col--lg-offset-2 {
		margin-left: 16.6667%;
	}

	.col--lg-offset-3 {
		margin-left: 25%;
	}

	.col--lg-offset-4 {
		margin-left: 33.3333%;
	}

	.col--lg-offset-5 {
		margin-left: 41.6667%;
	}

	.col--lg-offset-6 {
		margin-left: 50%;
	}

	.col--lg-offset-7 {
		margin-left: 58.3334%;
	}

	.col--lg-offset-8 {
		margin-left: 66.6667%;
	}

	.col--lg-offset-9 {
		margin-left: 75%;
	}

	.col--lg-offset-10 {
		margin-left: 83.3334%;
	}

	.col--lg-offset-11 {
		margin-left: 91.6667%;
	}
}