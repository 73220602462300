@import "equalizer";
@import "variables";
@import "typography";
@import "layout";
@import "components";
@import "animations";



// Screen reader text
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

/*
 * Browsehappy – used for older browsers that doesn't support modern features
 * 
 * Paste this code above </body>
<p id="browsehappy" class="browsehappy">
You are using an old web browser. The site won't work as expected in your browser.
Please <a href="http://browsehappy.com/" target="_blank">upgrade your browser</a> to increase your experience and safety.
</p>
 *
 */
.browsehappy {
  background-color: #333;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: .75em 1.5em;
  margin: 0;
  color: #FFF;
  z-index: 1000;
  display: none;
  font-size: 1em;
  line-height: 1.2;

  a {
    color: #FFF;
    text-decoration:underline;
  }
}
.no-flexboxlegacy {
  body {
    padding-top: 2.7em;
  }
  .browsehappy {
    display: block;
  }
}