.header,
.footer {
	transition: .5s ease-out;

	.loaded & {
		transform: translateY(0);
	}
}

.header {
	transform: translateY(-100%);
}

.section {
	opacity: 0;
	transition: .4s ease-out;
	transition-delay: .5s;

	.loaded & {
		opacity: 1;
	}
}

.stamp {
	transition: .2s ease-out;

	&:hover,
	&:focus {
		transform: rotate(-30deg) scale(1.2);
	}

	.touchevents & {
		&:hover,
		&:focus {
			transform: none;
		}
	}
}

.product__img {
	img {
		transition: .2s ease-out;

		&:hover {
			transform: scale(1.1);
		}
	}
}