html {
	scroll-behavior: smooth;
}

img {
	display: block;
	height: auto;
	width: 100%;
}

img,
.icon {
	display: block;
	max-width: 100%;
}

.site {
	background-color: $teal;
}

.logo {
	.icon {
		display: block;
		height: 45px;
		width: auto;
		margin: 0 auto;
		fill: $green;

		@media #{$tablet} {
			height: 60px;
		}

		@media #{$laptop} {
			height: 95px;
		}

		@media #{$desktop-large} {
			height: 95px;
		}
	}
}

.address {
	span {
		display: block;
	}
}

.stamp {
	img {
		width: 75px;

		@media #{$laptop} {
			width: 150px;
		}
	}
}

.wave {

	@media #{$tablet} {
		//margin-top: -15px;
	}

	@media #{$desktop} {
		margin-top: -5px;
	}

	@media #{$desktop-large} {
		margin-top: -50px;
	}
	.icon {
		display: block;
		height: auto;
		width: 100%;
		fill: $white;
		background-color: $teal;
	}
}

.info {
	font-size: 1.125rem;
	line-height: 1.2307;
	text-align: center;

	@media #{$tablet} {
		font-size: 1.625rem;
		line-height: 1.2307;
	}
	h2,
	p {
		max-width: 400px;
		margin-left: auto;
		margin-right: auto;

		@media #{$tablet} {
			max-width: 700px;
		}
	}

	h2 {
		font-weight: 700;
		max-width: 240px;

		@media #{$mobile} {
			max-width: 400px;
		}

		@media #{$tablet} {
			max-width: 700px;
		}
	}

	.footer__heading {
		max-width: none;
	}
}

.social {
	li {
		float: left;
		margin-right: $space / 2;

		@media #{$laptop} {
			float: right;
			margin-left: $space / 2;
			margin-right: 0;
		}
	}

	.icon {
		width: 30px;
		fill: $green;
	}

	&.social--contact {
		padding-top: $space / 4;
		display: none;

		@media #{$tablet} {
			display: block;
		}

		@media #{$laptop} {
			display: none;
		}
	}

	&.social--col {

		@media #{$tablet} {
			display: none;
		}

		@media #{$laptop} {
			display: block;
		}
	}
}

.showcase {

	.col--xs-6 {
		&:first-child {
			@media #{$tablet} {
				padding-bottom: 0;
			}

			.showcase__image {
				@media #{$tablet} {
					float: right;
				}
			}
		}
	}

	.showcase__image {
		@media #{$tablet} {
			float: left;
			max-width: 450px;
		}
	}

	.showcase__col {

		&:first-of-type {
			padding-right: $space/2;

			@media #{$tablet} {
				padding-right: $space;
			}
		}
		&:last-of-type {
			padding-left: $space/2;

			@media #{$tablet} {
				padding-left: $space;
			}
		}
	}
}

.products {
	.info {
		margin-bottom: $space;
	}

	.product {
		@media #{$laptop} {
			margin-bottom: $space / 2;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.product {
	background-color: $white;

	[class^="col--"],
	.row {
		margin-bottom: 0;
		padding-bottom: 0;
	}

	.product__header {
		padding-bottom: $space;

		@media #{$tablet} {
			padding-top: $space;
			padding-bottom: $space;
		}
	}
	.product__body {
		padding-bottom: $space/4;

		@media #{$tablet} {
			padding-top: $space;
			padding-bottom: $space;
		}
	}
	.product__body--ingredients {
		padding-bottom: $space;
	}

	.product__img {
		display: flex;
		align-items: center;
		background-color: $white;
		padding-top: $space;
		padding-bottom: $space;
	}

	.product__header {
		display: flex;
		flex-wrap: wrap;
		padding-top: $space;

		@media #{$tablet} {
			padding-bottom: 0;
		}

		@media #{$desktop} {
			padding-bottom: $space;
		}

		.product__header__title {
			width: 100%;

			span {
				display: block;

				&:first-child {
					font-size: 1.625rem;
					font-weight: 700;
					line-height: 1.2307;

					&:after {
						content: '';
						display: block;
						height: 4px;
						width: 50px;
						margin: $space / 4 0;
						background-color: $green;
					}
				}
			}
		}
	}

	.product__icon {
		float: left;
		width: 100px;
		max-width: 50%;
		margin-top: auto;
		padding-top: $space / 4;
		padding-right: $space / 2;
		@media #{$tablet} {
			padding-top: $space / 2;
		}
	}

	.product__body {
		h4 {
			font-weight: 700;
		}

		p {
			line-height: 1.2307;

			&:not(:last-child) {
				margin-bottom: $space / 4;
			}
		}
	}
}

.product--yellow {
	background-color: $yellow;
}

.product--pink {
	background-color: $pink;

	.product__header {
		.product__header__title {
			span {
				&:first-child {
					&:after {
						background-color: $red;
					}
				}
			}
		}
	}
}

.intro {
	padding-top: 0;
	padding-bottom: 0;
	@media #{$tablet} {
		padding-top: $space;
		padding-bottom: $space;
	}
}

.about {
	padding-top: $space/2;
	padding-bottom: $space/2;
	@media #{$tablet} {
		padding-top: $space;
		padding-bottom: $space;
	}
}

.header,
.footer {
	position: relative;
	background-color: $white;
}

.header {
	padding-top: $space;

	@media #{$tablet} {
		padding-top: $space/2;
	}

	@media #{$desktop} {
		padding-top: 0;
	}

	.section {
		padding: 0;
	}

	.logo,
	.stamp {
		position: absolute;
	}

	.logo {
		bottom: 34%;
		left: 0;
		right: 0;
		width: auto;

		@media #{$tablet} {
			bottom: 32%;
		}

		@media #{$laptop} {
			bottom: 31%;
		}
	}

	.stamp {
		bottom: -20px;
		right: 15px;

		@media #{$laptop} {
			bottom: -10%; 
			right: 15%;
		}
		@media #{$desktop} {
			bottom: 0; 
			right: 15%;
		}
	}
}

.footer {
	color: $green;

	.wave {
		margin-bottom: -2px;
	}

	.col--md-3 {
		@media #{$laptop} {
			&:first-of-type {
				padding-left: 0;
			}

			&:last-of-type {
				padding-right: 0;
			}
		}
	}

	.logo {
		display: none;

		@media #{$tablet} {
			display: block
		}

		.icon {
			height: 50px;
			margin-left: 0;
		}
	}

	.info {
		padding-bottom: $space / 2;
		font-size: 1rem;
		text-align: left;

		@media #{$tablet} {
			padding-bottom: 0;
		}

		p,
		.address {
			line-height: 1.2307;
			max-width: 220px;
			margin-left: 0;
		}
	}
}