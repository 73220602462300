@font-face {
	font-family: 'Proxima Nova';
	src: url('../font/hinted-ProximaNova-Regular.woff') format('woff'),
	url('../font/hinted-ProximaNova-Regular.ttf') format('truetype'),
	url('../font/hinted-ProximaNova-Regular.svg#ProximaNova-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Proxima Nova';
	src: url('../font/hinted-ProximaNova-Bold.woff') format('woff'),
	url('../font/hinted-ProximaNova-Bold.ttf') format('truetype'),
	url('../font/hinted-ProximaNova-Bold.svg#ProximaNova-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
}


body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'Proxima Nova', Arial, sans-serif;
	color: $black;
	line-height: 1.6;
}